<template>
  <b-form-group
    class="wameed-input text-book-16 text-font-main"
    :class="fieldClasses"
    :label="label"
    :label-for="id"
  >
    <validation-provider
      #default="{ errors }"
      :name="name"
      :vid="name"
      :rules="rules"
    >
      <b-input-group
        class="input-group-merge"
        :class="errors.length > 0 ? 'is-invalid' : null"
      >
        <b-input-group-prepend v-if="prepenIcon" is-text>
          <component
            class="cursor-pointer"
            v-if="prepenIcon"
            v-bind:is="prepenIcon"
            @click="prepenIconClick"
          ></component>
        </b-input-group-prepend>
        <b-form-input
          :id="id"
          @input="updateValue($event)"
          :class="inputClasses"
          :value="value"
          :state="errors.length > 0 ? false : null"
          :name="id"
          :placeholder="placeholder"
          :type="type"
        />
        <b-input-group-append v-if="icon" is-text>
          <component
            @click="iconClick"
            class="cursor-pointer"
            v-bind:is="icon"
          ></component>
        </b-input-group-append>
      </b-input-group>
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>


<script>
/* eslint-disable global-require */
import { ValidationProvider } from "vee-validate";
import { required, email } from "@validations";
export default {
  props: {
    prepenIcon: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    value: {
      type: String,
      default: null,
    },
    fieldClasses: {
      type: String,
      default: "",
    },
    inputClasses: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: "text",
    },
    rules: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    
  },
  components: {
    ValidationProvider,
  },
  data() {
    return {
      // validation rules
      required,
      email,
    };
  },
  methods: {
    iconClick() {
      this.$emit("iconClick");
    },
    prepenIconClick() {
      this.$emit("prepenIconClick");
    },
    updateValue(value) {
      this.$emit("input", value);
    },
  },
};
</script>